import { ActivatedRoute } from '@angular/router';
import { combineLatest, map, switchMap, Observable, Subject, Subscription } from 'rxjs';
import { Component, OnDestroy } from '@angular/core';
import { GroceryList, GroceryListItem } from '../../../types';
import { GroceryListService } from '../../services/grocery-list.service';
import { ItemStore } from '../../stores/item.store';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NewGroceryListItemsDialog } from '../../components/new-grocery-list-items-dialog/new-grocery-list-items-dialog.component';
import { StoreStore } from '../../stores/store.store';
import * as _ from 'lodash';

const aisleSort = (a: GroceryListItem, b: GroceryListItem) => (a.aisle! > b.aisle! ? 1 : -1);

@Component({
  selector: 'app-grocery-list-details',
  templateUrl: './grocery-list-details.page.html',
  styleUrls: ['./grocery-list-details.page.scss'],
})
export class GroceryListDetailsPage implements OnDestroy {
  groceryList: GroceryList;
  groceryList$: Observable<GroceryList>;
  groceryListId: string;
  groceryListIdSubject = new Subject<string>();
  groceryListSubject = new Subject<GroceryList>();
  private readonly subscriptions: Subscription[] = [];
  constructor(
    private bottomSheet: MatBottomSheet,
    private readonly snackBar: MatSnackBar,
    private readonly groceryListService: GroceryListService,
    private readonly itemStore: ItemStore,
    private readonly storeStore: StoreStore,
    private route: ActivatedRoute
  ) {
    this.groceryList$ = this.groceryListSubject.asObservable();
    this.subscriptions.push(
      combineLatest([
        this.groceryListIdSubject.asObservable().pipe(
          switchMap((id) => {
            return this.groceryListService.getGroceryList(id);
          })
        ),
        this.itemStore.itemMap$,
        this.storeStore.currentStore$,
      ])
        .pipe(
          map(([originalGroceryList, itemMap, currentStore]) => {
            const groceryList = _.cloneDeep(originalGroceryList);
            groceryList.items?.map((groceryItem) => {
              return Object.assign(groceryItem, itemMap.get(groceryItem.itemId), currentStore.items?.[groceryItem.itemId]);
            });
            groceryList.items.sort(aisleSort); // TODO: assign to dynamic sort
            return groceryList;
          })
        )
        .subscribe((groceryList) => {
          this.groceryListSubject.next(groceryList);
        })
    );
    this.subscriptions.push(
      this.route.paramMap.subscribe((paramMap) => {
        const id = paramMap.get('id');
        if (id) {
          this.groceryListId = id;
          this.groceryListIdSubject.next(id);
        }
      })
    );
  }

  newGroceryListItem(): void {
    this.bottomSheet
      .open(NewGroceryListItemsDialog, {
        disableClose: true,
        hasBackdrop: false,
      })
      .afterDismissed()
      .subscribe((newGroceryListItems) => {
        if (newGroceryListItems) {
          this.groceryListService.upsertGroceryItems(this.groceryListId, newGroceryListItems).subscribe({
            next: () => {
              // Force refresh
              this.groceryListIdSubject.next(this.groceryListId);
            },
            error: (err) => {
              console.error(err);
              this.snackBar.open('Error Creating Grocery List Items', '', {
                duration: 3000,
              });
            },
          });
        }
      });
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }
}
