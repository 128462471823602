<mat-drawer-container class="app-container" autosize>
  <mat-drawer #sidenav class="side-menu-container" mode="side">
    <div class="sidenav-header">
      <!-- TODO: for local development, will need to set up https for pictures to load -->
      <img class="profile-img" *ngIf="auth.user$ | async as user" [src]="user.picture" alt="P" />
    </div>
    <mat-nav-list>
      <a mat-list-item href="/grocery-list">Grocery Lists</a>
      <a mat-list-item href="/stores">Stores</a>
    </mat-nav-list>
  </mat-drawer>

  <div class="main-content-container">
    <mat-toolbar class="top-toolbar" color="primary">
      <div class="left-wrapper">
        <button (click)="sidenav.toggle()" aria-label="Toggle sidenav" class="sidenav-button-toggle" mat-icon-button type="button">
          <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
        </button>
        <span class="title">Grocer</span>
      </div>
      <div class="right-wrapper">
        <button class="store-dropdown-button" mat-button [matMenuTriggerFor]="storeDropdown">
          {{ currentStore.name }}
          <span *ngIf="currentStore.address">
            <span class="store-address">({{ currentStore.address.city }})</span>
          </span>
          <mat-icon>keyboard_arrow_down</mat-icon>
        </button>
        <mat-menu class="store-select" #storeDropdown="matMenu">
          <button (click)="selectStore(store)" *ngFor="let store of stores$ | async" mat-menu-item>
            {{ store.name }}
            <span *ngIf="store.address">
              <span class="store-address">({{ store.address.city }})</span>
            </span>
          </button>
        </mat-menu>

        <!-- TODO: probably just switch to mat-menu -->
        <!-- <mat-select class="store-select" (selectionChange)="selectStore($event.value)" [value]="storeId">
          <mat-option *ngFor="let store of stores$ | async" [value]="store.id">
            {{store.name}}, {{store.address?.city}}
          </mat-option>
        </mat-select> -->
      </div>
    </mat-toolbar>
    <router-outlet></router-outlet>
  </div>
</mat-drawer-container>
