import { Component } from '@angular/core';
import { GroceryList } from '../../../types';
import { GroceryListService } from '../../services/grocery-list.service';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NewGroceryListDialog } from '../../components/new-grocery-list-dialog/new-grocery-list-dialog.component';
import { Observable, Subject } from 'rxjs';

@Component({
  selector: 'app-grocery-list',
  templateUrl: './grocery-list.page.html',
  styleUrls: ['./grocery-list.page.scss'],
})
export class GroceryListPage {
  groceryLists: GroceryList[];
  groceryLists$: Observable<GroceryList[]>;
  groceryListsSubject = new Subject<GroceryList[]>();
  constructor(private bottomSheet: MatBottomSheet, private readonly groceryListService: GroceryListService, private readonly snackBar: MatSnackBar) {
    this.groceryLists$ = this.groceryListsSubject.asObservable();
    this.groceryListService.getGroceryLists().subscribe({
      next: (groceryLists) => {
        this.groceryLists = groceryLists;
        this.groceryListsSubject.next(groceryLists);
      },
      error: (err) => {
        console.error(err);
        this.snackBar.open('Error Loading Grocery Lists', '', {
          duration: 3000,
        });
      },
    });
  }

  newGroceryList(): void {
    this.bottomSheet
      .open(NewGroceryListDialog, {
        disableClose: true,
        hasBackdrop: false,
      })
      .afterDismissed()
      .subscribe((newGroceryList) => {
        if (newGroceryList) {
          this.groceryListService.createGroceryList(newGroceryList).subscribe({
            next: (groceryList) => {
              this.groceryLists.push(groceryList);
              this.groceryListsSubject.next(this.groceryLists);
            },
            error: (err) => {
              console.error(err);
              this.snackBar.open('Error Creating Grocery List', '', {
                duration: 3000,
              });
            },
          });
        }
      });
  }
}
