import { AuthService } from '@auth0/auth0-angular';
import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { Store } from '../../../types';
import { StoreStore } from '../../stores/store.store';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  readonly storeMap$: Observable<Map<string, Store>>;
  readonly stores$: Observable<Store[]>;
  currentStore: Store;
  constructor(public auth: AuthService, private readonly storeStore: StoreStore) {
    this.storeMap$ = this.storeStore.storeMap$;
    this.stores$ = this.storeStore.stores$;
    this.storeStore.currentStore$.subscribe((currentStore: Store) => {
      this.currentStore = currentStore;
    });
  }
  selectStore(store: Store) {
    this.storeStore.assignCurrentStore(store.id);
  }
}
