import { environment } from '../../environments/environment';
import { GroceryList, GroceryListItem, Store, StoreResponse } from '../../types';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class GrocerClient {
  constructor(private http: HttpClient) {}

  createGroceryList(groceryList: Partial<GroceryList>): Observable<GroceryList> {
    return this.http.post<GroceryList>(`${environment.api.baseUrl}/v1/grocery-list`, groceryList);
  }

  getGroceryList(id: string): Observable<GroceryList> {
    return this.http.get<GroceryList>(`${environment.api.baseUrl}/v1/grocery-list/${id}`);
  }

  getGroceryLists(): Observable<GroceryList[]> {
    return this.http.get<GroceryList[]>(`${environment.api.baseUrl}/v1/grocery-list`);
  }

  getStore(id: string): Observable<StoreResponse> {
    return this.http.get<StoreResponse>(`${environment.api.baseUrl}/v1/store/${id}`);
  }

  getStores(): Observable<Store[]> {
    return this.http.get<Store[]>(`${environment.api.baseUrl}/v1/store`);
  }

  upsertGroceryItems(id: string, items: GroceryListItem[]): Observable<GroceryListItem[]> {
    return this.http.put<GroceryListItem[]>(`${environment.api.baseUrl}/v1/grocery-list/${id}/grocery-list-item`, items);
  }
}
