// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

const GROCER_API_BASE_URL = 'https://dev.grocer-api.ianshoe.com';

export const environment = {
  auth0: {
    audience: GROCER_API_BASE_URL,
    clientId: 'dR5lWpKQW7XCtFUv9Bo5zWGPFrBcNIuF',
    domain: 'grocer-dev.us.auth0.com',
  },
  api: {
    baseUrl: GROCER_API_BASE_URL,
  },
  production: false,
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
