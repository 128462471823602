import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable, ReplaySubject, filter } from 'rxjs';
import { Item } from '../../types';

@Injectable({
  providedIn: 'root',
})
export class ItemStore {
  itemMap$: Observable<Map<string, Item>>;
  items$: Observable<Item[]>;
  private itemsSubject = new ReplaySubject<Item[]>(1);
  constructor(private readonly httpClient: HttpClient) {
    this.items$ = this.itemsSubject.asObservable();
    this.itemMap$ = this.items$.pipe(
      map((items) => {
        const itemsMap = new Map<string, Item>();
        items.forEach((item) => {
          itemsMap.set(item.id, item);
        });
        return itemsMap;
      })
    );

    this.httpClient.get<Item[]>('/assets/items.json').subscribe((items) => {
      this.itemsSubject.next(items);
    });
  }
}
