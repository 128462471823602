import { GrocerClient } from '../clients/grocer.client';
import { Store, StoreResponse } from '../../types';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class StoreService {
  constructor(private grocerClient: GrocerClient) {}

  getStore(id: string): Observable<StoreResponse> {
    return this.grocerClient.getStore(id);
  }

  getStores(): Observable<Store[]> {
    return this.grocerClient.getStores();
  }
}
