import { AuthModule, AuthHttpInterceptor } from '@auth0/auth0-angular';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { LayoutModule } from '@angular/cdk/layout';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatToolbarModule } from '@angular/material/toolbar';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { AppComponent } from './components/app/app.component';
import { AppRoutingModule } from './app-routing.module';
import { environment } from '../environments/environment';
import { GroceryListDetailsPage } from './pages/grocery-list-details/grocery-list-details.page';
import { GroceryListPage } from './pages/grocery-list/grocery-list.page';
import { GroceryListService } from './services/grocery-list.service';
import { ItemStore } from './stores/item.store';
import { NewGroceryListDialog } from './components/new-grocery-list-dialog/new-grocery-list-dialog.component';
import { NewGroceryListItemsDialog } from './components/new-grocery-list-items-dialog/new-grocery-list-items-dialog.component';
import { StoreService } from './services/store.service';
import { StoreStore } from './stores/store.store';

@NgModule({
  declarations: [AppComponent, GroceryListPage, GroceryListDetailsPage, NewGroceryListDialog, NewGroceryListItemsDialog],
  imports: [
    AppRoutingModule,
    AuthModule.forRoot({
      ...environment.auth0,
      cacheLocation: 'localstorage',
      useRefreshTokens: true,
      httpInterceptor: {
        allowedList: [
          {
            uri: `${environment.api.baseUrl}/*`,
          },
        ],
      },
    }),
    BrowserAnimationsModule,
    BrowserModule,
    FormsModule,
    HttpClientModule,
    LayoutModule,
    MatAutocompleteModule,
    MatBottomSheetModule,
    MatButtonModule,
    MatDialogModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatSidenavModule,
    MatSnackBarModule,
    MatToolbarModule,
    ReactiveFormsModule,
  ],
  providers: [GroceryListService, ItemStore, StoreStore, StoreService, { provide: HTTP_INTERCEPTORS, useClass: AuthHttpInterceptor, multi: true }],
  bootstrap: [AppComponent],
})
export class AppModule {}
