import { Component } from '@angular/core';
import { MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { GroceryList } from '../../../types';

@Component({
  selector: 'app-new-grocery-list-dialog',
  templateUrl: './new-grocery-list-dialog.component.html',
  styleUrls: ['./new-grocery-list-dialog.component.scss'],
})
export class NewGroceryListDialog {
  public newGroceryList: Partial<GroceryList> = { isTemplate: false }; // TODO: isTemplate;

  constructor(private bottomSheetRef: MatBottomSheetRef<NewGroceryListDialog>) {}

  cancel(): void {
    this.bottomSheetRef.dismiss();
  }

  create(): void {
    this.bottomSheetRef.dismiss(this.newGroceryList);
  }
}
