import { AuthGuard } from '@auth0/auth0-angular';
import { GroceryListPage } from './pages/grocery-list/grocery-list.page';
import { GroceryListDetailsPage } from './pages/grocery-list-details/grocery-list-details.page';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  { path: 'grocery-list', component: GroceryListPage, canActivate: [AuthGuard] },
  { path: 'grocery-list/:id', component: GroceryListDetailsPage, canActivate: [AuthGuard] },
  { path: '**', redirectTo: 'grocery-list', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
