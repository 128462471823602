<h1 mat-dialog-title>New Grocery Items</h1>
<div mat-dialog-content>
  <form [formGroup]="groceryListItemsForm" class="full-width">
    <mat-form-field appearance="fill">
      <mat-label>Item</mat-label>
      <input type="text" matInput formControlName="item" required [matAutocomplete]="itemAutoLink">
      <mat-autocomplete #itemAutoLink="matAutocomplete" [displayWith]="autocompleteDisplay">
        <mat-optgroup *ngFor="let group of groupOptions$ | async" [label]="group.category">
          <mat-option *ngFor="let item of group.items" [value]="item">
            {{item.name}}
          </mat-option>
        </mat-optgroup>
      </mat-autocomplete>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Quantity</mat-label>
      <input matInput formControlName="quantity" required type="number">
    </mat-form-field>
  </form>
</div>
<div mat-dialog-actions>
  <button mat-raised-button (click)="cancel()">Cancel</button>
  <button mat-raised-button [disabled]="groceryListItemsForm.invalid" (click)="addAnother($event)">Add Another</button>
  <button mat-raised-button [disabled]="groceryListItemsForm.invalid" (click)="save()">{{saveTextDisplay()}}</button>
</div>
