import { GrocerClient } from '../clients/grocer.client';
import { GroceryList, GroceryListItem } from '../../types';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class GroceryListService {
  constructor(private grocerClient: GrocerClient) {}

  createGroceryList(groceryList: Partial<GroceryList>): Observable<GroceryList> {
    return this.grocerClient.createGroceryList(groceryList);
  }

  getGroceryList(id: string): Observable<GroceryList> {
    return this.grocerClient.getGroceryList(id);
  }

  getGroceryLists(): Observable<GroceryList[]> {
    return this.grocerClient.getGroceryLists();
  }

  upsertGroceryItems(id: string, items: GroceryListItem[]): Observable<GroceryListItem[]> {
    return this.grocerClient.upsertGroceryItems(id, items);
  }
}
