<h1 mat-dialog-title>New Grocery List</h1>
<div mat-dialog-content class="full-width">
  <mat-form-field>
    <mat-label>Name</mat-label>
    <input matInput [(ngModel)]="newGroceryList.name" />
  </mat-form-field>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="cancel()">Cancel</button>
  <button mat-button (click)="create()">Create</button>
</div>
