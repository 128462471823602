import { StoreService } from '../services/store.service';
import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { Store } from '../../types';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class StoreStore {
  private readonly currentStoreSubject = new ReplaySubject<Store>(1);
  private readonly localStorageStores: { [key: string]: Store };
  private readonly storeMapSubject = new ReplaySubject<Map<string, Store>>(1);
  private readonly storesSubject = new ReplaySubject<Store[]>(1);
  readonly currentStore$: Observable<Store>;
  readonly storeMap$: Observable<Map<string, Store>>;
  readonly stores$: Observable<Store[]>;
  constructor(private readonly storeService: StoreService) {
    this.currentStore$ = this.currentStoreSubject.asObservable();
    this.storeMap$ = this.storeMapSubject.asObservable();
    this.stores$ = this.storesSubject.asObservable();
    const storeJson = localStorage.getItem('stores') ?? '{}';
    this.localStorageStores = JSON.parse(storeJson);
    this.storeService.getStores().subscribe((stores) => {
      stores.sort((a, b) => {
        if (a.name === b.name) {
          // @ts-ignore - undefined/null comparison is fine
          return a.address?.city < b.address?.city ? -1 : 1;
        } else {
          return a.name < b.name ? -1 : 1;
        }
      });
      const storeMap = new Map<string, Store>();
      stores.forEach((store) => {
        storeMap.set(store.id, store);
      });
      // TODO: handle not having selected stores setup yet
      this.assignCurrentStore(localStorage.getItem('currentStoreId') || 'ddeeac04-1d33-4046-b854-11ec48a8e94a');
      this.storeMapSubject.next(storeMap);
      this.storesSubject.next(stores);
    });
  }
  // TODO: localStorage TTL and forced refresh
  assignCurrentStore(id: string) {
    localStorage.setItem('currentStoreId', id);
    if (!this.localStorageStores[id]) {
      this.storeService.getStore(id).subscribe((storeResponse) => {
        const store = {
          ...storeResponse,
          items: _.keyBy(storeResponse.items, 'itemId'),
        };
        this.localStorageStores[id] = store;
        localStorage.setItem('stores', JSON.stringify(this.localStorageStores));
        this.currentStoreSubject.next(store);
      });
    } else {
      this.currentStoreSubject.next(this.localStorageStores[id]);
    }
  }
}
