import { Component } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { GroceryListItem, Item } from '../../../types';
import { ItemStore } from '../../stores/item.store';
import { combineLatest, map, Observable, startWith } from 'rxjs';
import { MatBottomSheetRef } from '@angular/material/bottom-sheet';
import * as _ from 'lodash';

type Group = {
  category: string;
  items: Item[];
};

type FormModel = {
  item?: Item;
  quantity: number;
};

@Component({
  selector: 'app-new-grocery-list-items-dialog',
  templateUrl: './new-grocery-list-items-dialog.component.html',
  styleUrls: ['./new-grocery-list-items-dialog.component.scss'],
})
export class NewGroceryListItemsDialog {
  groceryListItemsForm = this.formBuilder.group<FormModel>({
    item: undefined,
    quantity: 1,
  });

  public newGroceryListItem: Partial<GroceryListItem> = {};
  public newGroceryListItems: Partial<GroceryListItem>[] = [];
  public groupOptions$: Observable<Group[]>;

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly itemStore: ItemStore,
    public bottomSheetRef: MatBottomSheetRef<NewGroceryListItemsDialog>
  ) {
    this.groupOptions$ = combineLatest([
      this.groceryListItemsForm.get('item')!.valueChanges.pipe(startWith('')) as Observable<string | Item>,
      this.itemStore.items$.pipe(
        map((items) => {
          const groupMap = _.groupBy(items, 'cat');
          return Object.entries(groupMap).map(([category, items]) => {
            return {
              category,
              items,
            };
          });
        })
      ),
    ]).pipe(
      map(([value, groups]) => {
        if (value) {
          const filterValue = typeof value === 'string' ? value : value.name;
          return groups
            .map((group) => ({
              category: group.category,
              items: this._filterItems(group.items, filterValue),
            }))
            .filter((group) => group.items.length > 0);
        }
        return groups;
      })
    );
  }

  //TODO: better filter (like vscode's command palette)
  _filterItems(items: Item[], value: string): Item[] {
    const filterValue = value.toLowerCase();
    return items.filter((item) => item.name.toLowerCase().includes(filterValue));
  }

  addToList({ item, quantity }: FormModel): void {
    this.newGroceryListItems.push({
      itemId: item!.id,
      quantity: quantity,
    });
  }

  cancel(): void {
    this.bottomSheetRef.dismiss();
  }

  addAnother($event: Event): void {
    $event.stopPropagation();
    $event.preventDefault();
    this.addToList(this.groceryListItemsForm.value as FormModel);
    this.groceryListItemsForm.reset();
  }

  save(): void {
    if (this.groceryListItemsForm.valid) {
      this.addToList(this.groceryListItemsForm.value as FormModel);
    }
    this.bottomSheetRef.dismiss(this.newGroceryListItems);
  }

  autocompleteDisplay(option?: Item): string {
    return option?.name || '';
  }

  saveTextDisplay(): string {
    const suffix = this.newGroceryListItems.length ? ` (${this.newGroceryListItems.length})` : '';
    return `Save${suffix}`;
  }
}
