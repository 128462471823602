<!-- TODO: Filter and sort by aisle/category -->
<!-- TODO: make aisle/category a grouping rather than a label per item -->
<mat-list class="grocery-list-item-container">
  <mat-list-item class="list-item" *ngFor="let item of (groceryList$ | async)?.items">
    <div>
      <div class="aisle-badge" *ngIf="item.aisle">{{item.aisle}}</div>
      <div>{{item.name}} | {{item.quantity}}</div>
    </div>
  </mat-list-item>
  <!-- TODO: make this a fixed bottom element and have the list-container accommodate for it -->
  <mat-list-item class="list-item list-item-new" (click)="newGroceryListItem()">
    <i class="fa fa-plus fa-lg" aria-hidden="true"></i>
    <span>Add New Items</span>
  </mat-list-item>
</mat-list>
